@import url("https://fonts.googleapis.com/css?family=Roboto+Mono");
@import url("https://fonts.googleapis.com/css?family=Ceviche+One");

html,
body {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  font-family: "Roboto Mono", "Ceviche One";
  color: white;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}
.background {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1700px;
  background-image: url("./assets/background/Background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Overlay opacity */
  z-index: 0;
}

footer {
  display: none; /* none=OFF, block=ON */
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 20px;
  text-align: center;
  font-size: 15px;
  color: white;
  margin-bottom: 5px;
  margin-top: 230px;
}

@media only screen and (orientation: landscape) {
  .background {
    background-size: auto 100%;
    background-position: center top;
  }
}
